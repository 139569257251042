export default [
  {
    title: "home",
    route: "home",
    icon: "HomeIcon",
  },
  {
    title: "employee",
    icon: "UserIcon",
    children: [
      {
        title: "home",
        route: "employee",
      },
      {
        title: "add_employee",
        route: "add-employee",
      },
    ],
  },
  {
    title: "evaluation",
    route: "evaluation",
    icon: "StarIcon",
  },
  {
    title: "report",
    route: "report",
    icon: "FileIcon",
  },
  {
    title: "setting",
    route: "setting",
    icon: "SettingsIcon",
  },
  {
    title: "contract_us",
    route: "contract-us",
    icon: "HelpCircleIcon",
  },
];
