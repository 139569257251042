export default [
  {
    title: "home",
    route: "admin-home",
    icon: "HomeIcon",
  },
  {
    title: "company",
    icon: "CreditCardIcon",
    children: [
      {
        title: "companies.main",
        route: "admin-company-list",
      },
      {
        title: "companies.add",
        route: "admin-add-company",
      },
    ],
  },
  {
    title: "employee",
    icon: "UserIcon",
    children: [
      {
        title: "home",
        route: "employee",
      },
      {
        title: "add_employee",
        route: "add-employee",
      },
    ],
  },
  {
    title: "evaluation",
    icon: "StarIcon",
    children: [
      {
        title: "evaluations.main",
        route: "admin-evaluation-main",
      },
      {
        title: "evaluations.approve",
        route: "admin-evaluation-approve",
      },
      {
        title: "evaluations.setting",
        route: "admin-setting-evaluation",
      },
    ],
  },
  {
    title: "report",
    route: "admin-report",
    icon: "FileIcon",
  },
  {
    title: "setting",
    route: "admin-setting",
    icon: "SettingsIcon",
  },
];
